import React from 'react'
import Link from 'next/link'
import getConfig from 'next/config'

import { style } from './SiteHeader.style'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const SiteHeader: React.FC = () => {
  return (
    <header css={style.header} id="site_header">
      <img
        src={`${basePath}/common/top_title.png`}
        alt="北関東・甲信越HIV感染症症例検討会"
        css={style.titleImg}
      />
    </header>
  )
}

export default SiteHeader
